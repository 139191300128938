html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  background-color: white;
  color: white;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
}

.App {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
